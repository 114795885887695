$(function() {
  var lastsearch = '';

  $('.sub-search').on('focus', function () {
    $('#search-alert').addClass('d-flex').removeClass('d-none');
  });

  $('.sub-search').on('focusout', function () {
    if ($('.sub-search').val().length <= 0) {
      $('#search-alert').addClass('d-none').removeClass('d-flex');
    }
  });

  $('.sub-search').on('keyup', function () {
    var currentsearch = $(this).val().toLowerCase();
    $('.product').each(function () {
      var product_found = false;
      var product = $(this);

      product.find('h5, span.sku').each(function () {
        var search = $(this).text();
        product_found = product_found || search.toLowerCase().search(currentsearch) >= 0;
      });

      if (product_found) {
        product.show();
      } else {
        product.hide();
      }

    });
  });

  $('.bought-products .btn-selected').on('click', function () {
    if($(this).hasClass('btn-active')) {
      $(this).parents('.table-row').find('.selected-note').removeClass('d-none');
    } else {
      $(this).parents('.table-row').find('.selected-note').addClass('d-none');
    }
  });

  $('.bought-products .selected-note textarea').on('change', function () {
    var post_data = {
      'quantity': '-',
      'note': $(this).val()
    };

    $.post('/ajax/selected/add/' + $(this).data('product-id'), post_data);
  });
});
