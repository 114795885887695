import { createApp } from "vue/dist/vue.esm-bundler";
import axios from "axios";
import VueAxios from "vue-axios";
import EventBus from "../vue/EventBus.js";
import * as Sentry from '../sentry.js';

if (document.getElementById("cart") !== null) {
  const cart = createApp({
    data() {
      return {
        list_type: "cart",
        url_prefix: "",
        cart: {},
        sortorder: "created_at:desc",
        isLoading: false,
      };
    },
    methods: {
      changed (item) {
        const vm = this;

        this.clearTimeout(item);

        item.debounce = setTimeout(() => {
          var debounce = item.debounce;
          vm.isLoading = true;

          vm.$http
            .post("/ajax/cart/add/" + item.product.id, {
              quantity: item.quantity,
            })
            .then(function (response) {
              if (debounce == item.debounce) {
                let dataLayerItem = dataLayerOtherItems.find((obj) => {
                  return obj.product_id == item.product.id;
                });

                if(dataLayerItem) {
                  dataLayerItem.index = 1;
                  var dataLayerEvent;
                  if (item.quantity > item.cart_quantity) {
                    dataLayerEvent = "add_to_cart";
                    dataLayerItem.quantity = item.quantity - item.cart_quantity;
                  } else {
                    dataLayerEvent = "remove_from_cart";
                    dataLayerItem.quantity = item.cart_quantity - item.quantity;
                  }

                  dataLayer.push({
                    event: dataLayerEvent,
                    ...{ ecommerce: { items: dataLayerItem } },
                  });
                }

                vm.getData();
              }
            }).catch(error => {
              if(error.response !== undefined && error.response.status !== undefined && error.response.status == 403) {
                location.reload();
              } else {
                Sentry.captureException(error);
              }
            });
        }, 400);
      },
      clearTimeout (item) {
        if (item.debounce) {
          clearTimeout(item.debounce);
        }
      },
      remove (item) {
        const vm = this;
        vm.isLoading = true;
        vm.$http
          .delete("/ajax/cart/remove/" + item.product.id)
          .then(function (response) {
            let dataLayerItem = dataLayerItems.find((obj) => {
              return obj.product_id == item.product.id;
            });

            if(dataLayerItem) {
              var dataLayerEvent = "remove_from_cart";
              dataLayerItem.index = 1;
              dataLayerItem.quantity = item.cart_quantity;

              dataLayer.push({
                event: dataLayerEvent,
                ...{ ecommerce: { items: dataLayerItem } },
              });
            }

            vm.getData();
            EventBus.emit("update-headercart");
          }).catch(error => {
            if(error.response !== undefined && error.response.status !== undefined && error.response.status == 403) {
              location.reload();
            } else {
              Sentry.captureException(error);
            }
            vm.isLoading = false;
          });
      },
      clearCart () {
        const vm = this;
        vm.isLoading = true;
        this.$http.delete("/ajax/cart/clear").then(function (response) {
          Object.keys(dataLayerItems).map(function (objectKey, index) {
            var dataLayerItem = dataLayerItems[objectKey];
            if (dataLayerItem.quantity > 0) {
              var dataLayerEvent = "remove_from_cart";
              dataLayerItem.quantity = dataLayerItem.cart_quantity;

              dataLayer.push({
                event: dataLayerEvent,
                ...{ ecommerce: { items: dataLayerItem } },
              });
            }
          });

          vm.getData();
          EventBus.emit("update-headercart");
        }).catch(error => {
          if(error.response !== undefined && error.response.status !== undefined && error.response.status == 403) {
            location.reload();
          } else {
            Sentry.captureException(error);
          }
          vm.isLoading = false;
        });
      },
      getData () {
        this.isLoading = true;
        const vm = this;
        this.$http
          .get(
            "/ajax/cart/content?sortorder=" + this.sortorder,
            {
              headers: {
                'Accept-Language': LANGUAGE,
              }
            }
          )
          .then(function (response) {
            vm.cart = response.data;
            vm.isLoading = false;
          }).catch(error => {
            if(error.response !== undefined && error.response.status !== undefined && error.response.status == 403) {
              location.reload();
            } else {
              Sentry.captureException(error);
            }
            vm.isLoading = false;
          });
      },
      parseEntities (string) {
        var txt = document.createElement("textarea");
        txt.innerHTML = string;
        return txt.value;
      },
    },
    created() {
      this.url_prefix = URL_PREFIX;
      this.getData();
    },
    mounted() {
    }
  })
    .use(VueAxios, axios)
    .mount("#cart");
}
